import * as api from '@utils/api'
import { CreditType } from '@types'

export const getUserCredits = async (userId: number): Promise<CreditType[]> => {
  const response = await api.GET(`/p4/users/${userId}/credits`)

  return response.data
}

export const createUserCredit = async (
  userId: number,
  amount: number,
  productType: string,
): Promise<CreditType> => {
  const response = await api.POST(`/p4/users/${userId}/credits`, {
    amount,
    product_type: productType,
  })

  return response.data
}

export const updateUserCredit = async (
  userId: number,
  creditId: number,
  amount: number,
  productType: string,
): Promise<CreditType> => {
  const response = await api.PUT(`/p4/users/${userId}/credits/${creditId}`, {
    amount,
    product_type: productType,
  })

  return response.data
}

export const deleteUserCredit = async (
  userId: number,
  creditId: number,
): Promise<CreditType> => {
  const response = await api.DELETE(`/p4/users/${userId}/credits/${creditId}`)

  return response.data
}
