import ProductTypeTrigger from './parts/product-type-trigger'
import ProductsTrigger from './parts/products-trigger'
import React, { ReactElement, useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'

import { PromotionType } from '@types'

type PropsType = {
  className: string | null
  promotion: PromotionType | null
  editing: boolean
  onConfigurationChange: (object) => void
}

type ConfigurationType = {
  triggering_product_ids: number[]
  triggering_product_type: string | null
  percentage_off?: number | null
  cents_off?: number | null
  item_limit_per_day: number
}

const useStyles = makeStyles()((theme) => ({
  paperWrapper: {
    padding: theme.spacing(2),
  },
  paperMargin: {
    marginTop: theme.spacing(2),
  },
}))

const Discounted = ({
  className,
  promotion,
  editing,
  onConfigurationChange,
}: PropsType): ReactElement => {
  const { classes } = useStyles()
  const [trigger, setTrigger] = useState('products')
  const [productType, setProductType] = useState('')
  const [productIds, setProductIds] = useState<number[]>([])
  const [percentageOff, setPercentageOff] = useState<number | null>(null)
  const [centsOff, setCentsOff] = useState<number | null>(null)
  const [itemLimitPerDay, setItemLimitPerDay] = useState<number | null>(null)
  const [discountMode, setDiscountMode] = useState<'percentage' | 'cents'>(
    'percentage',
  )
  const currentConfig = promotion ? promotion.configuration : {}
  const config: ConfigurationType = {
    triggering_product_ids: [],
    triggering_product_type: null,
    item_limit_per_day: 1,
    ...currentConfig,
  }

  const resetConfiguration = () => {
    setProductType('')
    setProductIds([])
    setPercentageOff(0.0)
    setCentsOff(null)
    setItemLimitPerDay(null)
  }

  useEffect(() => {
    resetConfiguration()
  }, [])

  useEffect(() => {
    if (!promotion) return
    if (config.triggering_product_type) {
      setTrigger('type')
      setProductType(config.triggering_product_type)
    } else {
      setProductIds(config.triggering_product_ids)
    }
    setItemLimitPerDay(config.item_limit_per_day)
    setPercentageOff(config.percentage_off ? config.percentage_off : null)
    setCentsOff(config.cents_off ? config.cents_off : null)
    setDiscountMode(config.cents_off ? 'cents' : 'percentage')
  }, [promotion])

  useEffect(() => {
    prepareConfiguration()
  }, [productType, productIds, itemLimitPerDay, percentageOff, centsOff])

  const handleTriggerTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetConfiguration()
    setTrigger(e.target.value)
  }

  const prepareConfiguration = () => {
    const newConfig: any = {
      item_limit_per_day: itemLimitPerDay,
    }

    if (trigger == 'type') {
      newConfig.triggering_product_type = productType
    } else {
      newConfig.triggering_product_ids = productIds
    }

    if (percentageOff) {
      newConfig.percentage_off = percentageOff
    }

    if (centsOff) {
      newConfig.cents_off = centsOff
    }

    onConfigurationChange(newConfig)
  }

  const handleItemLimitPerDayChange = (e: any) => {
    let value = e.target.value
    value = value ? parseInt(value, 10) : null
    setItemLimitPerDay(value)
  }

  const handlePercentageOffChange = (e: any) => {
    let value = e.target.value
    value = value ? parseFloat(value) : 0.0
    if (value > 100) value = 100.0
    setPercentageOff(value)
  }

  const handleCentsOffChange = (e: any) => {
    let value = e.target.value
    value = value ? parseInt(value, 10) : 0
    setCentsOff(value)
  }

  const handleChangeDiscountMode = (_, value) => {
    setDiscountMode(value as 'percentage' | 'cents')
    if (value == 'cents') {
      setPercentageOff(null)
      setCentsOff(0)
      return
    }

    setCentsOff(null)
    setPercentageOff(0.0)
  }

  const discountAmountInput = {
    percentage: () => (
      <>
        <TextField
          disabled={!editing}
          label='Percentage Off'
          onChange={handlePercentageOffChange}
          value={percentageOff}
        />

        <p>
          Every eligible item a customer buys will be discounted by this
          percentage of the items base price.
        </p>
      </>
    ),
    cents: () => (
      <>
        <TextField
          disabled={!editing}
          label='Cents Off'
          onChange={handleCentsOffChange}
          value={centsOff}
        />

        <p>
          Every eligible item a customer buys will be discounted by this amount
          in cents off of the items base price.
        </p>
      </>
    ),
  }

  return (
    <div className={className || ''}>
      <Paper className={classes.paperWrapper}>
        <div>
          <FormControl>
            <FormLabel>Discount</FormLabel>
            <RadioGroup
              onChange={handleChangeDiscountMode}
              row
              value={discountMode}
            >
              <FormControlLabel
                control={<Radio />}
                disabled={!editing}
                label='Percentage'
                value='percentage'
              />
              <FormControlLabel
                control={<Radio />}
                disabled={!editing}
                label='Cents'
                value='cents'
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div>{discountAmountInput[discountMode]()}</div>

        <div>
          <TextField
            disabled={!editing}
            label='Item Limit Per Day'
            onChange={handleItemLimitPerDayChange}
            value={itemLimitPerDay}
          />

          <p>
            Limits the total number of items that can be given this discount per
            day. Leaving this field blank will result in infinite potentially
            discounted items.
          </p>
        </div>
        <div>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>Promotion Is Triggered By</FormLabel>
            <RadioGroup
              aria-label='type'
              name='type'
              onChange={handleTriggerTypeChange}
              value={trigger}
            >
              <FormControlLabel
                control={<Radio />}
                disabled={!editing}
                label='Trigger By Products'
                value='products'
              />
              <FormControlLabel
                control={<Radio />}
                disabled={!editing}
                label='Trigger By Product Type'
                value='type'
              />
            </RadioGroup>
          </FormControl>
        </div>
      </Paper>

      <div className={classes.paperMargin}>
        {trigger == 'products' && (
          <ProductsTrigger
            editing={editing}
            onChangeProductIds={setProductIds}
            productIds={productIds}
          />
        )}

        {trigger == 'type' && (
          <ProductTypeTrigger
            editing={editing}
            onProductTypeChange={setProductType}
            productType={productType}
          />
        )}
      </div>
    </div>
  )
}

export default Discounted
