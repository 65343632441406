import React, { ReactElement, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'

interface Props {
  open: boolean
  onClose: () => void
  onConfirm: (newValues: { amount: number; productType: string }) => void
  creditValues: { amount: number; productType: string }
}

const CreditDialog = ({
  open,
  onClose,
  onConfirm,
  creditValues,
}: Props): ReactElement => {
  const [amount, setAmount] = useState(0)
  const [productType, setProductType] = useState('beverage')

  useEffect(() => {
    setAmount(creditValues.amount)
    setProductType(creditValues.productType)
  }, [creditValues])

  const handleOnConfirm = () => {
    onConfirm({ amount, productType })
  }

  const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(parseInt(e.target.value, 10))
  }

  const handleChangeProductType = (_, productType) => {
    setProductType(productType)
  }

  return (
    <Dialog open={open}>
      <DialogTitle>New Customer Comp</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Applies to one item up to amount provided.
        </DialogContentText>
        <DialogContentText>
          <Box sx={{ display: 'flex', flexDirection: 'column', paddingY: 2 }}>
            <FormControl component='fieldset'>
              <TextField
                label='Amount (cents)'
                onChange={handleChangeAmount}
                placeholder='0'
                sx={{ marginBottom: 1 }}
                type='number'
                value={amount}
              />
            </FormControl>
            <FormControl component='fieldset'>
              <FormLabel component='legend'>Product Type</FormLabel>
              <RadioGroup
                aria-label='type'
                name='type'
                onChange={handleChangeProductType}
                value={productType}
              >
                <FormControlLabel
                  control={<Radio />}
                  label='Beverage'
                  value='beverage'
                />
                <FormControlLabel
                  control={<Radio />}
                  label='Food'
                  value='food'
                />
                <FormControlLabel control={<Radio />} label='Bag' value='bag' />
                <FormControlLabel
                  control={<Radio />}
                  label='Bottle'
                  value='bottle'
                />
                <FormControlLabel
                  control={<Radio />}
                  label='Merchandise'
                  value='merchandise'
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleOnConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreditDialog
